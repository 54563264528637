/* ----------------WhatsApp CSS---------------- */
.whatsapp {
    position: fixed;
    z-index: 999;
    top: 75px;
    right: 30px;
    display: none;
    transition: all 0.5s ease;
    height: 36px;
    width: 36px;
    line-height: 33px;
    color: green !important;
    /* animation: shadow-pulse 1s infinite; */
    /* border-radius: 25%; */
    border-radius: 50%;
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(124, 223, 32, 0.5);
    border-radius: 50%;
  }
  100% {
    box-shadow: 0 0 0 30px rgba(124, 223, 32, 0);
    border-radius: 50%;
  }
}

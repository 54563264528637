
.calendly-badge-widget {
  bottom: -10px !important;
  left: 50% !important;
  right: calc(50% - 100px) !important;
  margin-left: -100px!important;
  animation: shadow-pulse 2s infinite;
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(57,108,240, 0.6);
    border-radius: 25px;
  }
  100% {
    box-shadow: 0 0 0 30px rgba(57,108,240, 0);
    border-radius: 25px;
  }
}
.calendly-inline-widget {
  /* height: 750px; */
  overflow: hidden;
}

.bg-50{
    background-color: rgba(0, 0, 0, 0.4);
    padding-inline: 5%;
    padding-block: 3%;
}
.counter-box h2{
    font-size: 42px;
}
.pt-100{
    padding-top: 5vh;
}
.btn-call {
    /* color: #396CF0; */
    color: #ffffff;
    border-radius: 25px;
    background-color: #396CF0;
    font-size: 24px;
    font-weight: 700;
    border: 0;
    padding: 0.5em 1.5em; /* Shorthand for padding */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.7); /* Shadow effect */
    transition: box-shadow 0.3s ease; /* Smooth transition for the shadow effect */
    animation: pulse 2s infinite; /* Pulse animation */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.faq-badge{
    position: absolute;
    top: 3%;
}
.calendly-inline-widget iframe[src*="calendly.com"] {
    overflow: hidden;
    height: 750px;
  }

  ul li {
    margin-inline-start: 0px;
}
.home-wave-bottom .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 150px;
    z-index: 0;
    -webkit-animation: inherit !important;
    animation: inherit;
    opacity: 1;
    border-radius: 0;
    background: transparent;
}
.home-wave-bottom .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.home-wave-bottom .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.home-wave-bottom  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.home-wave-bottom  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.home-wave-bottom  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
@keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
}
.footer-wave .banner_shape1 {
    top: 400px !important;
    left: 10% !important;
    animation: mymove 10s infinite;
}
.footer-wave .banner_shape2 {
    top: 280px !important;
    right: 12% !important;
    animation: mymove 5s infinite;
}
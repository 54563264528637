/* Sticky note css */

.sticky-container {
    position: relative;
    top: -50px;
    left: -10px;
    margin: 1em;
    z-index: 999;
  }

  .sticky-card {
    text-decoration:none;
    color:#5c483f;
    background:rgba(57,108,240,0.6);
    display:block;
    height:10em;
    width:18em;
    padding:0.75em;
    -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
    -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    transition: transform 0.15s linear;
    -moz-transition: -moz-transform 0.15s linear;
    -o-transition: -o-transform 0.15s linear;
    -webkit-transition: -webkit-transform 0.15s linear;
    transform: rotate(-15deg);
    position: relative;
    /* top: 5px; */
    /* right: 5px; */
    border-radius: 5%;
    /* animation: fadeInDownBig 5s ease forwards, swing 2s infinite alternate; */
  }
  .sticky-card img {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 30px;
  }
  
  .sticky-card-container {
    text-align: center;
  }
  
  .sticky-card h2 {
    font-size: 125%;
    font-weight: bold;
  }
  .sticky-card h3 {
    font-size: 120%;
    font-weight: bold;
  }
  
  .sticky-card .old-price {
    font-size: 130%;
    margin-bottom: 0%;
    text-decoration: line-through;
  }
  .sticky-card .new-price {
    font-size: 200%;
    margin: 0%;
    padding: 0%;
    line-height: normal;
    color: #ffffff;
    /* text-shadow: #000; */
    font-weight: 800;
    text-shadow: 1px 1px #396df09f;
    box-shadow: 0 0 0 0px rgba(124, 223, 32, 0.5);
  }
  @keyframes fadeInDownBig {
    0% {
      opacity: 0;
      transform: translateY(-2000px) scale(3);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  @keyframes swing {
    0% {
      transform: rotate(9deg);
    }
    50% {
      transform: rotate(-3deg);
    }
    100% {
      transform: rotate(9deg);
    }
  }
  .sticky-card:hover,
  .sticky-card:focus {
    box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
    transform: scale(1.25);
    /* transform: rotate(9deg); */
    position: relative;
    z-index: 5;
  }
  
/* lottie animation style */
.shareSize {
    align-items: center !important;
    width: 320px !important;
    margin: 0 auto !important;
  }
.youtubeAnimation {
    align-items: center !important;
    /* height: 200px !important; */
    margin: auto 0 !important;
  }

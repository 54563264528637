:root {
  --light-purple: #F6F4FE;
  --purple: #6A49F2;
  --bg-purple: #6A49F2;
  --dark-purple: #32236F;
  --body-text-purple: #3E3F66;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --slider-dots-color: #D4D2DD;
  --light-bg: #DFDAF3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

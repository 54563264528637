//
// helper.scss
//
.section {
    padding: 100px 0;
    position: relative;

    @media (max-width: 768px) {
        padding: 60px 0;
    }
}
.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

//Title
.heading {
    color: #5c483f !important;
}
.heading-title {
    line-height: 26px;
    .heading {
        font-size: 42px !important;
        letter-spacing: 1px;
    }
    .title {
        font-size: 32px !important;
        letter-spacing: 1px;
    }
    .para-desc {
        font-size: 17px;
    }
}
.section-title {
    position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 24px !important;
    }
    .sub-title {
        letter-spacing: 0.5px;
        font-size: 28px !important;
    }
}
.para-desc {
    max-width: 1000px;
    text-align: justify;
}
.mt-100 {
    margin-top: 100px;
}

.bg-white-50 {
    background: rgba($bg-white-50, 0.5) !important;
}


//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }

    &.integration-hero {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        height: 250px;
        background: $white;

        @media (max-width: 768px) {
            height: 140px;
        }

        @media (max-width: 425px) {
            height: 60px;
        }
    }
    
    @media (max-width: 425px) {
        bottom: -4px;
    }
}

//Features Absolute
.features-absolute {
    position: relative;
    margin: -200px 0 0px;
    z-index: 2;
    transition: all 0.5s ease;

    @media (max-width: 768px) {
        margin: -140px 0 0;
    }

    &.career-form {
        margin: -145px 0 0px;
        
        @media (max-width: 768px) {
            margin: -105px 0 0px;
        }
    }
}

//Feature post placeholder
.feature-posts-placeholder {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    height: 66.6%;

    @media (max-width: 425px) {
        height: 80%;
    }
}

@media (max-width: 768px) {
    .heading-title {
        .heading {
            font-size: 36px !important;
        }
        .para-desc {
            font-size: 18px;
        }
    }
    .section-title {
        .title {
            font-size: 24px !important;
        }
    }
    .mt-60 {
        margin-top: 60px;
    }
}

//Feather Icon
.fea {
    stroke-width: 1.8;
    &.icon-sm {
        height: 16px;
        width: 16px;
    }
    &.icon-ex-md {
        height: 20px;
        width: 20px;
    }
    &.icon-m-md {
        height: 28px;
        width: 28px;
    }
    &.icon-md {
        height: 35px;
        width: 35px;
    }
    &.icon-lg {
        height: 42px;
        width: 42px;
    }
    &.icon-ex-lg {
        height: 52px;
        width: 52px;
    }
}

// Iconscote unicons
[class^="uil-"],
[class*=" uil-"] {
    &:before {
        margin: 0;
    }
}

.uim-svg {
    vertical-align: 0 !important;
}

// Rounded
.rounded {
    border-radius: 5px !important;
}
.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.rounded-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.rounded-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
.border {
    border: 1px solid $gray-200 !important;
}
.border-top {
    border-top: 1px solid $gray-200 !important;    
}
.border-bottom {
    border-bottom: 1px solid $gray-200 !important;    
}
.border-left {
    border-left: 1px solid $gray-200 !important;    
}
.border-right {
    border-right: 1px solid $gray-200 !important;    
}

//back to top
.back-to-top {
    position: fixed;
    z-index: 99;
    bottom: 30px;
    right: 30px;
    display: none;
    transition: all 0.5s ease;
    height: 36px;
    width: 36px;
    line-height: 33px;
    color: $back-to-top-color !important;
    &:hover {
        background: darken($primary, 5%) !important;
    }
}

//Social icons
.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            display: inline-block;
            height: 32px;
            width: 32px;
            line-height: 30px;
            text-align: center;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            .fea-social {
                stroke-width: 2;
            }
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $foot-social-color-white !important;
                .fea-social {
                    fill: $primary;
                }
            }
        }
    }
    &.footer-social {
        li {
            a {
                color: $foot-social-color;
                border-color: lighten($footer, 5%);
            }
        }
    }
    &.social {
        li {
            a {
                color: $foot-social-color;
                border-color: $social-border-color;
            }
        }
    }
}

//back-to-home
.back-to-home {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

//Sticky Sidebar
.sticky-bar {
    position: sticky !important;
    top: 80px;
}

//Text-color-white
.text-color-white {
    color: $text-color-white !important;
}

.d-flex {
    .flex-1 {
        flex: 1;
    }
}

//Maring nagative
.margin-nagative {
    &.mt-n5 {
        margin-top: -48px;
    }
}

//Upload Image
.preview-box {
    img {
        max-width: 100%;
        height: auto;
        border-radius: 6px;
    }
}